import React from 'react';
import './style.css';
import { isMobile, isSafari } from 'react-device-detect';
import { Container, Nav, Navbar } from 'react-bootstrap';
import translate from '../../translation';
import { logo } from '../../assets/images';
import { Link } from 'react-router-dom';
/* RESTOS DU COEUR */
const { REACT_APP_LINK_RESTOS_DU_COEUR } = process.env;

const Header = ({ isBannerOpened }) => {
	const toggleRef = React.createRef();
	const collapseRef = React.createRef();

	const collapse = () => {
		if (collapseRef.current.className.includes('show')) {
			toggleRef.current.click();
		}
	};

	return (
		<Navbar
			expand="lg"
			className="navbar"
			style={{
				marginTop: isMobile && isBannerOpened && !isSafari ? '60px' : '',
			}}
		>
			<Container>
				<Link onClick={collapse} to="/">
					<img src={logo} alt={'logo'} className="logo" />
				</Link>
				{/* RESTOS DU COEUR */}
				<div className="ml-lg-3">
					<a href={REACT_APP_LINK_RESTOS_DU_COEUR} target="blank">
						<div className="donRestosLink ms-2"></div>
					</a>
				</div>
				<Navbar.Toggle ref={toggleRef} aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse ref={collapseRef} id="responsive-navbar-nav">
					<Nav className="ms-auto">
						<Link onClick={collapse} className="nav-link" to="/ecouter-radioplayer">
							{translate('Nos écouter')}
						</Link>
						<Link onClick={collapse} className="nav-link" to="/radios">
							{translate('Liste des radios')}
						</Link>
						<Link onClick={collapse} className="nav-link" to="/podcasts">
							{translate('Liste des podcasts')}
						</Link>
						<Link onClick={collapse} className="nav-link" to="/articles">
							{translate('Notre actualité')}
						</Link>
						<Link onClick={collapse} className="nav-link" to="/a-propos">
							{translate('A Propos')}
						</Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Header;
