import React from 'react';
import './style.css';

export default function Event() {
	return (
		<div id="event">
			<a href="https://beta-site.radioplayer.fr/radio/1322-Radio-Restos">
				<div className="bannerRestosLink"></div>
			</a>
		</div>
	);
}
