import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { croppedBg1, croppedBg2, croppedBg3, googlePlay, appStore } from '../../assets/images';
import translate from '../../translation';
import { SearchBar, Loading, Empty, FeedItem, Event } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFeaturedRadios } from '../../store/actions/radio';
import { getCategories } from '../../store/actions/category';
import { Categories, CategoriesHome, Features, PodcastsTop } from './childs';
import { Helmet } from 'react-helmet';
import { getPodcastsTop } from '../../store/actions/podcasts';
import { getArticles } from '../../store/actions/feed';

/**
 * Affiche la page Home
 * @returns JSX
 */
function Home() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { podcastsTop } = useSelector((state) => state.podcasts);
	const { featured } = useSelector((state) => state.radio);
	const { suggestions } = useSelector((state) => state.search);
	const { categories } = useSelector((state) => state.category);
	const { innerWidth: width } = window;
	const { allFeeds } = useSelector((state) => state.feed);
	const [podcastsMostListened, setPodcastsMostListened] = useState({});

	useEffect(() => {
		const asyncProcess = () => {
			setLoading(true);
			dispatch(getFeaturedRadios());
		};
		asyncProcess();
		setLoading(false);
	}, [dispatch]);

	useEffect(() => {
		const asyncProcess = () => {
			setLoading(true);
			dispatch(getPodcastsTop());
		};
		asyncProcess();
		setLoading(false);
	}, [dispatch]);

	useEffect(() => {
		dispatch(getCategories());
	}, [dispatch]);

	useEffect(() => {
		const asyncProcess = () => {
			dispatch(getArticles(0, 3));
		};
		asyncProcess();
		setLoading(false);
	}, [dispatch]);

	useEffect(() => {
		if (podcastsTop && podcastsTop.length > 0) {
			setPodcastsMostListened(
				podcastsTop.find((podcast) => podcast.name === translate('Podcasts les + écoutés'))
			);
			setPodcastsMostListened((prevData) => ({
				...prevData,
				series: prevData?.series?.slice(0, 10),
			}));
		}
	}, [podcastsTop]);

	const onClick = () => {
		navigate('/radios');
	};

	return (
		<div id="homePage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section0">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-md-center">
							<h1>{translate('Vos radios préférées sont')} </h1>
							<SearchBar />
							<Button onClick={onClick} className="allRadios" variant="secondary">
								{translate('Toutes les radios')}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section1" style={{ position: suggestions.length > 0 ? '' : 'relative' }}>
				<Container>
					{/* RESTOS DU COEUR */}
					<div className="eventSection">
						<Event />
					</div>
					<h2 className="section1Title">{translate('homeSection1RadiosTitle')}</h2>
					<div className="radiosBlock">
						{loading ? (
							<Loading color="#cf2345" size={50} conClass="loading" />
						) : featured.length === 0 ? (
							<Empty text={translate('Aucune radio disponible')} />
						) : (
							<Features featured={featured} />
						)}
					</div>
					<h2 className="section1Title">{translate('homeSection1CatsTitle')}</h2>
					<div className="catsBlock">
						{width >= 768 ? (
							<CategoriesHome categories={categories} />
						) : (
							<Categories categories={categories} />
						)}
					</div>
				</Container>
			</section>
			<section className="section05">
				<div className="podcastsTopHome-container">
					{loading ? (
						<Loading color="#cf2345" size={50} conClass="loading" />
					) : !podcastsMostListened ? (
						<Empty text={translate('Aucun podcast disponible')} />
					) : (
						<PodcastsTop podcastsTop={podcastsMostListened} />
					)}
				</div>
			</section>
			<section className="section2">
				<Container>
					<Row>
						<Col sm={7}>
							<h2>{translate('homeSection2LeftTitle')}</h2>
							<div className="d-flex flex-row justify-content-md-end align-items-center rpCroppedView">
								<div className="rpCard me-3" style={{ backgroundImage: `url(${croppedBg1})` }} />
								<div className="me-md-4">
									<div className="rpCard mb-3" style={{ backgroundImage: `url(${croppedBg2})` }} />
									<div
										className="rpCard rpCardAfter"
										style={{
											backgroundImage: `linear-gradient(to bottom, rgba(207 ,35 ,69 , .68), rgba(207 ,35 ,69 , .68)),url(${croppedBg3})`,
										}}
									/>
								</div>
							</div>
						</Col>
						<Col sm={5} className="d-flex flex-column justify-content-center">
							<h2>{translate('homeSection2RightTitle')}</h2>
							<p className="rpTextDesc">{translate('homeSection2RightText')}</p>
							<Button onClick={onClick} className="me-auto" variant="primary">
								{translate('Voir la liste des radios')}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section3">
				<Container>
					<Row>
						<Col md="5">
							<h2>{translate('homeSection3Title')}</h2>
							<p>{translate('homeSection3Text')}</p>
							<h5>{translate('homeSection3Download')}</h5>
							<div className="mt-5">
								<Button
									target="_blank"
									href="https://apps.apple.com/fr/app/radioplayer/id6443602613"
									className="appLink me-2"
									variant="link"
								>
									<img src={appStore} alt="" />
								</Button>
								<Button
									target="_blank"
									href="https://play.google.com/store/apps/details?id=com.radioplayer.mobile&pcampaignid=web_share"
									className="appLink"
									variant="link"
								>
									<img src={googlePlay} alt="" />
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section4">
				<Container>
					<h2 className="text-md-center mb-md-5">{translate('Notre actualité')}</h2>
					<Row>
						{allFeeds.map((item) => (
							<Col md="4" key={item?.id?.toString()}>
								<FeedItem item={item} />
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</div>
	);
}

export default Home;
